 
import { Injectable, Directive } from '@angular/core';  
import { s_reports } from 'app/main/Reports/ReportFrame/ReportFrame.data';
import { s_transinformation, VMs_transinfo } from './window/Basewindow.data';
@Injectable()

export class globalData
{ 

    public ls_fy_code:string="";
    public ls_trc_code:string=""; 
    public ln_vr_no:number=0;


    public ls_ref_fy_code:string="";
    public ls_ref_trc_code:string="";
    public ln_ref_vr_no:number=0;

    public Drilldown_from_to_do:boolean=false;
    public ls_Drilldown_from_to_do:string="";

    private ls_data 
    public gs_data:string;  
    public gs_datenull:string="01/01/1900";
    
    public gs_report:s_reports
    public gs_transinfo:VMs_transinfo

    lsBaseURL:string;   
    lsBaseURLCatalog:string; 
    constructor(){ 
      this.lsBaseURLCatalog="http://localhost:1892/"
      //test api
      
       //Test 
      
       
       //live 
     this.lsBaseURLCatalog= "https://apps.sharjahports.gov.ae/portals/" 
       //test
     // this.lsBaseURLCatalog= "https://apps.sharjahports.gov.ae/testportal/" 
      // "https://apimea2.epromisonline.com:22096/shj_ports/"  
       //this.lsBaseURL="https://mena.epromisonline.com:446/epromis_live_api/" 
       
       //this.lsBaseURL=  "https://mena.epromisonline.com:446/epromisapi/"  
      
   // this.lsBaseURL=   "https://portals.epromis.online:8443/epromisapi/"
     //this.lsBaseURL="http://localhost:1892/"
        //this.lsBaseURL="https://app.epromis.online:4443/epromisapi/"
      //  this.lsBaseURL="https://mena.epromisonline.com:446/epromisapi/"  
        //"https://mena.epromisonline.com:446/epromisapi/"
        //"https://app.epromis.online:4443/epromisapi/"  
        //"https://mena.epromisonline.com:446/epromis_live_api/" 
        //"https://mena.epromisonline.com:446/epromis_2020_api/" 
        //"https://app.epromis.online:4443/epromisapi/" 
        // this.lsBaseURL="http://localhost:1892/"
       
         
    } 
     
                 
        
    setData(val) {
        this.ls_data = val;
    }
    getData() {
        return this.ls_data; 
    }
    geturl() {
      return this.lsBaseURLCatalog; 
  }


} 


